/* You can add global styles to this file, and also import other style files */
@import '../node_modules/font-awesome/css/font-awesome.css';

// header base skins
.kt-header-base-light {
  @import 'sass/global/layout/header/skins/base/light.scss';
}
.kt-header-base-dark {
  @import 'sass/global/layout/header/skins/base/dark.scss';
}

// header menu skins
.kt-header-menu-light {
  @import 'sass/global/layout/header/skins/menu/light.scss';
}
.kt-header-menu-dark {
  @import 'sass/global/layout/header/skins/menu/dark.scss';
}

// brand skins
.kt-brand-dark {
  @import 'sass/global/layout/brand/skins/dark.scss';
}
.kt-brand-light {
  @import 'sass/global/layout/brand/skins/light.scss';
}

// aside skins
.kt-aside-dark {
  @import 'sass/global/layout/aside/skins/dark.scss';
}
.kt-aside-light {
  @import 'sass/global/layout/aside/skins/light.scss';
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
  width: 100% !important;
}

.position-static {
  position: static;
}

#toast-container > div {
  opacity: 1;
}

.ngx-toastr {
  font-weight: 400;
  width: 375px !important;

  .toast-message {
    div:first-child {
      margin-bottom: 8px;
    }
  }
}

.dx-selectbox.is-invalid {
  border-color: #fd397a;
}

.dx-datebox.is-invalid {
  border-color: #fd397a;
}

.dx-numberbox.is-invalid {
  border-color: #fd397a;
}

.dx-tagbox-popup-wrapper .dx-list-select-all {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.dx-treeview-select-all-item {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

ngx-extended-pdf-viewer .pdfViewer .page {
  box-sizing: content-box;
}
